import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaComments } from 'react-icons/fa'; // Icône de chat
import { WiStars } from "react-icons/wi";
import api from '../../api';
import { UserContext } from '../../context/UserContext';  // Importer l'URL de l'API

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);  // Gérer l'ouverture et la fermeture du chatbot
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);  // Gérer le chargement
    const [animatedMessage, setAnimatedMessage] = useState('');  // Message animé
    const messageEndRef = useRef(null);  // Référence pour faire défiler vers le bas
    const { user } = useContext(UserContext);

    // Fonction pour faire défiler jusqu'en bas après chaque nouveau message
    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSend = async () => {
        if (!input) return;

        // Ajouter le message de l'utilisateur
        const userMessage = { sender: 'user', text: input };
        setInput('')
        setMessages([...messages, userMessage]);
        setAnimatedMessage('');  // Réinitialiser le message animé

        // Activer le mode "chargement" pendant que le bot répond
        setLoading(true);

        try {
            // Envoyer le message à l'API Django
            const response = await api.post('chatbot/', {
                message: input,
                user_info: user,  // Envoie les informations utilisateur
            });

            const botResponse = response.data.response || 'Désolé, une erreur est survenue.';
            setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: botResponse }]);
        } catch (error) {
            const botErrorResponse = 'Erreur de communication avec le chatbot.';
            setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: botErrorResponse }]);
        } finally {
            // Désactiver le mode "chargement"
            setLoading(false);
        }
    };

    // Permettre l'envoi avec la touche "Entrée"
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !loading) {
            handleSend();
        }
    };

    return (
        <div className="fixed bottom-6 right-6 z-50">
            {!isOpen && (
                <div
                    className="bg-yellow-500 text-black p-4 rounded-full shadow-lg cursor-pointer hover:bg-yellow-400 transition duration-200"
                    onClick={() => setIsOpen(true)}
                >
                    <FaComments size={24} />
                </div>
            )}

            {isOpen && (
                <div className="bg-gray-900 text-white rounded-lg shadow-lg w-96 h-[calc(60vh-100px)] max-h-full flex flex-col transition-all duration-300">
                    {/* Header */}
                    <div className="bg-yellow-500 text-black p-3 rounded-t-lg cursor-pointer flex items-center justify-between hover:bg-yellow-400 transition duration-200" onClick={() => setIsOpen(false)}>
                        <span className="flex items-center">
                            <WiStars className="ml-2 text-white" size={30} /> {/* Petite étoile d'IA */}
                            Stonqle Assistant
                        </span>
                        <span className="text-xl cursor-pointer">&times;</span>
                    </div>

                    {/* Zone de messages */}
                    <div className="flex-1 p-4 overflow-y-auto bg-gray-800 border-t border-gray-700 custom-scrollbar">
                        {messages.length === 0 ? (
                            <p className="text-gray-400 text-sm">Posez-moi une question...</p>
                        ) : (
                            messages.map((msg, index) => (
                                <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
                                    <div className={`inline-block px-4 py-2 rounded-lg ${msg.sender === 'user' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}>
                                        {msg.text}
                                    </div>
                                </div>
                            ))
                        )}
                        <div ref={messageEndRef}></div> {/* Permet de scroller jusqu'ici */}
                        {loading && (
                            <div className="text-gray-400 text-sm">Le chatbot réfléchit...</div>
                        )}
                    </div>

                    {/* Zone d'input */}
                    <div className="p-2 bg-gray-900 border-t border-gray-700">
                        <div className="flex">
                            <input
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={handleKeyPress}  // Envoi avec "Entrée"
                                placeholder="Écrivez un message..."
                                className="flex-1 p-2 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            />
                            <button
                                onClick={handleSend}
                                className="ml-2 bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-600 transition"
                                disabled={loading}  // Désactiver pendant le chargement
                            >
                                {loading ? 'En cours...' : 'Envoyer'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// Custom scroll bar CSS
const styles = `
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
}
`;

// Injecter le style de la scroll bar
const CustomStyles = () => {
    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.textContent = styles;
        document.head.appendChild(styleElement);
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    return null;
};

export default Chatbot;
