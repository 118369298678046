import React, { useContext, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../api';
import { UserContext } from "../../context/UserContext";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ subscriptionId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useContext(UserContext);
    const navigate = useNavigate(); // Corrected usage of useNavigate

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        if (!stripe || !elements) {
            setError('Stripe.js has not loaded.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        // Attempt to create a payment method
        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (stripeError) {
            console.error('Stripe error:', stripeError);
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/create-subscription/', {
                paymentMethodId: paymentMethod.id,
                customer_id: user.id,
                price_id: subscriptionId,
            });

            const subscriptionResult = response.data;
            if (subscriptionResult.status === 'error') {
                NotificationManager.error(subscriptionResult.message || 'Subscription failed');
                setLoading(false);
                return;
            }
            const userResponse = await api.get('/current-user/');
            localStorage.setItem('user', JSON.stringify(userResponse.data));

            NotificationManager.success('Paiement réussi.');
            navigate('/'); // Redirect to home or appropriate page
        } catch (apiError) {
            console.error('API error during subscription creation:', apiError);
            setError('An error occurred while processing your request.');
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className="bg-gradient-to-r from-gray-200 via-gray-300 to-gray-400 text-gray-900 p-8 rounded-xl shadow-xl w-96">
            <h2 className="text-3xl font-extrabold mb-4 text-yellow-900">Payez votre abonnement</h2>
            <CardElement className="mb-4 border p-3 rounded-md shadow-inner bg-white" />
            {error && <p className="text-red-600 mb-4">{error}</p>}
            <button
                type="submit"
                disabled={!stripe || loading}
                className={`w-full py-3 mt-4 rounded-lg font-semibold text-white hover:opacity-90 transition-opacity duration-300 bg-indigo-700 ${loading ? 'opacity-50' : ''}`}
            >
                {loading ? 'Processing...' : 'Payer'}
            </button>
        </form>
    );
};

export default CheckoutForm;
