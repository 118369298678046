import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { formatAmount, printAmountWithBenefits } from "../../utils";

const PositionCard = ({ position, handleCardClick, openTransactionModal, openCurrentAmountModal, hideAmounts, liveValue }) => {
    return (
        <div
            key={position.id}
            className="bg-gray-900 rounded-lg shadow-lg p-6 flex flex-col justify-between relative cursor-pointer"
            onClick={() => handleCardClick(position)}
        >
            <div className="flex items-center space-x-4">
                {position.asset.image_url && (
                    <img src={position.asset.image_url} alt={position.asset.name} className="h-12 w-12 rounded-full" />
                )}
                <div>
                    <h2 className="text-lg font-semibold text-white">{position.asset.name}</h2>
                    <p className="text-sm text-gray-400">
                        Dernière mise à jour: {position.date ? (new Date(position.date).toLocaleString()) : ''}
                    </p>
                </div>
            </div>

            <div className="mt-4">
                <p className="text-sm text-gray-400">
                    <span className="text-white">{printAmountWithBenefits(liveValue?.value ?? position.current_amount, position.invested_amount, hideAmounts)}</span>
                </p>
                <p className="text-sm text-gray-400">
                    Profits réalisés : &nbsp;
                    <span className={position.total_profits < 0 ? "text-red-500" : "text-green-500"}>
                        {formatAmount(position.total_profits ?? 0, hideAmounts)}
                    </span>
                </p>
                <p className="text-sm text-gray-400">
                    Quantité: <span className="text-white">{parseFloat(position.quantity)}</span>
                </p>
            </div>

            <div className="mt-6 flex justify-end space-x-4">
                <button
                    onClick={(e) => { e.stopPropagation(); openTransactionModal(position, 'sell'); }}
                    className="p-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md"
                >
                    <FontAwesomeIcon icon={faMinus} className="w-4 h-4" />
                </button>
                <button
                    onClick={(e) => { e.stopPropagation(); openTransactionModal(position, 'buy'); }}
                    className="p-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md"
                >
                    <FontAwesomeIcon icon={faPlus} className="w-4 h-4" />
                </button>
                <button
                    onClick={(e) => { e.stopPropagation(); openCurrentAmountModal(position); }}
                    className="p-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-400 transition duration-200 transform hover:-translate-y-1 hover:shadow-md absolute bottom-6 left-6"
                >
                    <FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
                </button>
            </div>
        </div>
    );
};

export default PositionCard;
