import React from 'react';
import { printAmountWithBenefits } from '../../utils';

const AssetCategoryCard = ({ label, icon, hideAmounts, totalPortfolios, amount, invested, liveGlobalValue }) => {
    return (
        <div className="bg-gray-900 shadow-md rounded-lg mb-4 p-4 flex flex-col justify-between items-center sm:flex-row sm:justify-between hover:bg-gray-800 transition duration-300 ease-in-out w-full min-h-[150px]">
            {/* Icône et nom de la catégorie */}
            <div className="flex items-center mb-3 sm:mb-0">
                <span className="text-yellow-500 text-3xl sm:text-4xl mr-3">
                    {icon}
                </span>
                <h4 className="text-lg sm:text-xl font-bold text-yellow-500">
                    {label}
                </h4>
            </div>

            {/* Montants et informations */}
            <div className="text-center sm:text-right">
                <p className="text-gray-400 text-sm sm:text-base">Portefeuilles : {totalPortfolios || 0}</p>

                {/* Gestion du hideAmounts */}
                <div className="text-white text-base sm:text-lg font-semibold">
                    {printAmountWithBenefits(liveGlobalValue ?? amount, invested, hideAmounts, 1.5)}
                </div>
            </div>
        </div>
    );
};

export default AssetCategoryCard;
