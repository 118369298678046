import React, { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

const UpdatePositionValue = ({ position, onClose, onUpdate }) => {
    const [currentAmount, setCurrentAmount] = useState(parseFloat(position.current_amount).toFixed(2));
    const [investedAmount, setInvestedAmount] = useState(parseFloat(position.invested_amount).toFixed(2));
    const [date, setDate] = useState(new Date());

    const handleSubmit = () => {
        onUpdate(position.asset.id, currentAmount, date, 0, 'edit', investedAmount);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 z-10 w-full max-w-md mx-auto">
                <h2 className="text-2xl text-white mb-4">Modifier la valeur de la position</h2>
                <div className="mb-4">
                    <label className="block text-white mb-2">Montant investi</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        onChange={(e) => setInvestedAmount(e.target.value)}
                        defaultValue={parseFloat(position.invested_amount)}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Montant actuel</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        onChange={(e) => setCurrentAmount(e.target.value)}
                        defaultValue={parseFloat(position.current_amount)}
                    />
                </div>
                <div className="mb-4 w-full">
                    <label className="block text-yellow-400 mb-2" htmlFor="datePicker">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>
                        Date
                    </label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        id="datePicker"
                    />
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-400 transition duration-200"
                    >
                        Mettre à jour
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdatePositionValue;
