import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Turnstile } from '@marsidev/react-turnstile';
import api from '../api';

const ActivateAccount = () => {
    const { uidb64, token } = useParams();
    const [status, setStatus] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function activate() {
            try {
                const response = await api.post(`/activate/${uidb64}/${token}/`, { captcha_token: captchaToken });
                setStatus(response.data);
                if (response.data === 'Account activated successfully') {
                    NotificationManager.success('Compte activé avec succès !');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                }
            } catch (error) {
                setStatus('Activation link is invalid');
                NotificationManager.error('L\'activation a échoué.');
            }
        }

        if (captchaToken) {
            activate();
        }
    }, [uidb64, token, captchaToken, navigate]);

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        setReloadCaptcha(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Activation du compte</h2>
                {status ? (
                    <p className={`text-lg mb-6 ${status === 'Activation link is invalid' ? 'text-red-600' : 'text-green-600'}`}>
                        {status === 'Activation link is invalid' ? 'L\'activation du compte a échoué !' : 'Compte activé avec succès !'}
                    </p>
                ) : (
                    <Turnstile
                        className="mx-auto"
                        siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onSuccess={handleCaptchaChange}
                    />
                )}
                {status === 'Activation link is invalid' && (
                    <div className="mt-6">
                        <a
                            href="/resend-activation"
                            className="bg-blue-600 text-white py-2 px-4 rounded shadow hover:bg-blue-700 transition duration-300"
                        >
                            Renvoyer le lien d'activation
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActivateAccount;
