import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import UpdatePositionValue from './UpdatePositionValue';
import api from '../../api';
import PositionCard from './PositionCard';
import {useNavigate} from "react-router-dom";
import CreateTransaction from "../transactions/CreateTransaction";

const PositionList = ({ positions, handleAddPosition, setRefreshView, refreshView, portfolioId, hideAmounts, liveValue }) => {
    const [transactionModalOpen, setTransactionModalOpen] = useState(false);
    const [currentAmountModalOpen, setCurrentAmountModalOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [transactionType, setTransactionType] = useState(null);
    const navigate = useNavigate();

    const openCurrentAmountModal = (asset) => {
        setSelectedPosition(asset);
        setCurrentAmountModalOpen(true);
    };

    const closeCurrentAmountModal = () => {
        setSelectedPosition(null);
        setCurrentAmountModalOpen(false);
    };

    const openTransactionModal = (asset, type) => {
        setSelectedPosition(asset);
        setTransactionType(type);
        setTransactionModalOpen(true);
    };

    const closeTransactionModal = () => {
        setSelectedPosition(null);
        setTransactionModalOpen(false);
    };

    const handleCardClick = (position) => {
        navigate(`/position/${position.id}`, { state: { position } });
    };

    const handleCreateTransaction = async (assetId, amount, date, quantity, transactionType, investedAmount) => {
        try {
            const payload = {
                portfolioId,
                assetId,
                amount,
                date,
                quantity,
                transactionType,
                investedAmount
            };

            await api.post(`/create-transaction/`, payload);

            setRefreshView(true);
        } catch (error) {
            console.error('Error updating asset:', error);
        }
    };

    // Trier les actifs par montant (value) décroissant
    const sortedPositions = positions.sort((a, b) => b.value - a.value);

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
            {/* Carte vide pour ajouter un nouvel actif */}
            <div
                className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col justify-center items-center cursor-pointer transition duration-200 transform hover:-translate-y-1 hover:shadow-lg hover:bg-gray-700"
                onClick={handleAddPosition}
            >
                <FontAwesomeIcon icon={faPlus} className="text-white text-4xl mb-4" />
                <p className="text-white text-center">Ajouter un nouvel actif</p>
            </div>

            {sortedPositions.map(position => (
                <PositionCard
                    key={position.id}
                    position={position}
                    handleCardClick={handleCardClick}
                    openTransactionModal={openTransactionModal}
                    openCurrentAmountModal={openCurrentAmountModal}
                    hideAmounts={hideAmounts}
                    liveValue={liveValue?.positions.find(p => p.position_id === position.id)}
                />
            ))}

            {currentAmountModalOpen && (
                <UpdatePositionValue
                    position={selectedPosition}
                    onClose={closeCurrentAmountModal}
                    onUpdate={handleCreateTransaction}
                />
            )}

            {transactionModalOpen && (
              <CreateTransaction
                position={selectedPosition}
                transactionType={transactionType}
                onClose={closeTransactionModal}
                onCreate={handleCreateTransaction}
                />
            )}
        </div>
    );
};

export default PositionList;
