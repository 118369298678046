import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getLatestValue, printAmountWithBenefits } from '../../utils';

const PortfolioCard = ({ portfolio, liveValue }) => {
    const { preferences } = useContext(UserContext);
    const { hideAmounts } = preferences;

    return (
        <Link to={`/portfolio-history/${portfolio.id}`} className="block bg-gray-800 shadow-lg rounded-lg overflow-hidden mb-6 hover:bg-gray-700 transition duration-300 ease-in-out w-full border-2 border-gray-600">
            <div className="flex h-full">
                <div className="flex-shrink-0 h-full w-32 flex items-center justify-center bg-gray-900">
                    {portfolio.image_url && <img src={portfolio.image_url} alt={portfolio.name} className="h-full w-full object-cover rounded-xl" />}
                </div>
                <div className="flex flex-col justify-center flex-1 p-4">
                    <h4 className="text-xl font-bold text-yellow-500">{portfolio.name}</h4>
                    <p className="text-gray-300 mb-4">{portfolio.description}</p>
                    <span className={`text-xl font-semibold text-white`}>
                        {printAmountWithBenefits(liveValue?.total_value ?? portfolio.metadata.total_amount, portfolio.metadata.total_invested, hideAmounts, 1)}
                    </span>
                    <p className="text-sm text-gray-400 mt-2">
                        Dernière mise à jour : {new Date(portfolio.last_updated).toLocaleDateString()}
                    </p>
                </div>
            </div>
        </Link>
    );
};

export default PortfolioCard;
