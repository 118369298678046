import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import api from '../../../api';

const RegisterStep1 = ({ nextStep, setPersonalInfo }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const birthDate = new Date(dateOfBirth);
        const today = new Date();
        const minAgeDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

        if (birthDate > minAgeDate) {
            NotificationManager.error('Date de naissance invalide. Vous devez avoir au moins 18 ans.');
            return;
        }

        try {
            await api.post('/register/step1/', { first_name: firstName, last_name: lastName });
            setPersonalInfo({ firstName, lastName, dateOfBirth });
            nextStep();
        } catch (error) {
            NotificationManager.error('Une erreur est survenue. Veuillez réessayer.');
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-center text-yellow-500 mb-4">Informations personnelles</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="firstName">
                        Prénom
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="lastName">
                        Nom
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="dateOfBirth">
                        Date de naissance
                    </label>
                    <input
                        type="date"
                        id="dateOfBirth"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                    Suivant
                </button>
            </form>
        </div>
    );
};

export default RegisterStep1;
