import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCoins, faCalculator, faPiggyBank, faMoneyBillWave, faPercentage, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { UserContext } from "../../../context/UserContext";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreatePositionForm = ({ selectedAsset, setSelectedAsset, category, portfolioPositions }) => {
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [customAssetName, setCustomAssetName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [unitValue, setUnitValue] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [useInvestedAmount, setUseInvestedAmount] = useState(true);
    const [initialBenefitsPercentage, setInitialBenefitsPercentage] = useState(0);
    const [investedAmount, setInvestedAmount] = useState(0);
    const [date, setDate] = useState(new Date()); // Pour DateTimePicker
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [assets, setAssets] = useState([]);
    const isFetchableCategory = ['crypto', 'stock', 'etf', 'nft', 'commodity'].includes(category);

    const fetchAssets = async () => {
        try {
            const response = await api.get('/assets/', {
                params: {
                    category: category,
                    search: searchQuery,
                    page: page,
                    user: user.id,
                },
            });
            const fetchedAssets = response.data.results
                .filter(asset => !portfolioPositions.some(existingPosition => existingPosition.asset.id === asset.id))
                .map(asset => ({
                    value: asset.id,
                    label: asset.name,
                    shortName: asset.short_name,
                    imageUrl: asset.image_url,
                }));

            setAssets([
                { value: 'other', label: 'Personnalisé...' },
                ...fetchedAssets
            ]);
        } catch (error) {
            console.error('Erreur lors de la récupération des actifs', error);
            NotificationManager.error('Erreur lors de la récupération des actifs');
        }
    };

    useEffect(() => {
        fetchAssets();
    }, [searchQuery, page]);

    const formatOptionLabel = ({ label, imageUrl }) => (
        <div className="flex items-center">
            {imageUrl && <img src={imageUrl} alt={label} className="h-6 w-6 rounded-full mr-2" />}
            <span>{label}</span>
        </div>
    );

    const handleAssetChange = async (selectedOption) => {
        setSelectedAsset(selectedOption);
    };

    const handleInvestedAmountChange = (value) => {
        setInvestedAmount(value);
        if (!isNaN(totalAmount) && totalAmount > 0 && value) {
            setInitialBenefitsPercentage(((totalAmount - value) / value * 100));
        }
    };

    const handleBenefitsPercentageChange = (value) => {
        setInitialBenefitsPercentage(value);
        if (!isNaN(totalAmount) && totalAmount > 0 && value) {
            setInvestedAmount((totalAmount / (1 + value / 100)).toFixed(2));
        }
    };

    useEffect(() => {
        if (!useInvestedAmount) {
            handleBenefitsPercentageChange(initialBenefitsPercentage);
        } else {
            handleInvestedAmountChange(investedAmount);
        }
    }, [totalAmount]);

    const handleQuantityChange = (value) => {
        setQuantity(value);
        setTotalAmount((unitValue * value).toFixed(2));
    };

    const handleTotalValueChange = (value) => {
        setTotalAmount(value);
        if (quantity > 0) {
            setUnitValue((value / quantity).toFixed(2));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                portfolioId: id,
                quantity,
                date,
                user: user.id,
                amount: totalAmount,
                investedAmount: investedAmount,
                transactionType: 'buy',
                assetId: selectedAsset.value,
            };

            if (selectedAsset.value === 'other' && customAssetName) {
                payload.asset_name = customAssetName;
                payload.type = category;
            }
            await api.post('/create-transaction/', payload);

            navigate(`/portfolio-history/${id}`);
            NotificationManager.success('Actif ajouté avec succès');
        } catch (error) {
            NotificationManager.error('Erreur lors de la création de l\'actif');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (inputValue) => {
        setSearchQuery(inputValue);
        setPage(1);
    };

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#2d3748',
            borderColor: state.isFocused ? '#ffa500' : '#4a5568',
            color: 'white',
            borderRadius: '0.375rem',
            padding: '5px',
            boxShadow: state.isFocused ? '0 0 0 1px #ffa500' : 'none',
            '&:hover': {
                borderColor: '#ffa500',
            }
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#2d3748',
            borderRadius: '0.375rem',
            marginTop: 0,
            border: '1px solid #4a5568',
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            backgroundColor: '#2d3748',
            borderRadius: '0.375rem',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#ffa500' : state.isFocused ? '#4a5568' : '#2d3748',
            color: state.isSelected ? 'white' : '#e2e8f0',
            padding: '10px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#4a5568',
                color: 'white',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#a0aec0',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        input: (provided) => ({
            ...provided,
            color: 'white',
        }),
    };

    return (
        <form onSubmit={handleSubmit} className="rounded-lg">
            {isFetchableCategory && <div className="mb-4">
                <label className="block text-yellow-400 mb-2" htmlFor="asset">
                    <FontAwesomeIcon icon={faCoins} className="mr-2"/>
                    Sélectionner un actif
                </label>
                <Select
                    id="asset"
                    className="w-full text-black"
                    value={selectedAsset}
                    onChange={handleAssetChange}
                    onInputChange={handleSearchChange}
                    options={[...assets]}
                    placeholder="Rechercher..."
                    isClearable
                    isSearchable
                    formatOptionLabel={formatOptionLabel}
                    styles={customSelectStyles}
                />
            </div>}

            {(selectedAsset?.value === 'other' || !isFetchableCategory) && (
                <div className="mb-4">
                    <label className="block text-yellow-400 mb-2" htmlFor="customAssetName">
                        <FontAwesomeIcon icon={faPiggyBank} className="mr-2"/>
                        Nom de l'actif personnalisé
                    </label>
                    <input
                        type="text"
                        id="customAssetName"
                        className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                        value={customAssetName}
                        onChange={(e) => setCustomAssetName(e.target.value)}
                        placeholder="Entrez le nom de l'actif personnalisé"
                        required
                    />
                </div>
            )}

            <div className="mb-4">
                <label className="block text-yellow-400 mb-2" htmlFor="quantity">
                    <FontAwesomeIcon icon={faCalculator} className="mr-2"/>
                    Quantité
                </label>
                <input
                    type="text"
                    id="quantity"
                    min="0"
                    step="any"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={quantity}
                    onChange={(e) => handleQuantityChange(e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-yellow-400 mb-2" htmlFor="totalAmount">
                    <FontAwesomeIcon icon={faCoins} className="mr-2"/>
                    Montant total (bénéfices inclus)
                </label>
                <input
                    type="text"
                    min="0"
                    step="any"
                    id="totalAmount"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                    value={totalAmount}
                    onChange={(e) => handleTotalValueChange(e.target.value)}
                />
            </div>

            <div className="mb-4">
                <div className="flex items-center">
                    <span className="text-white mr-3">Pourcentage de bénéfices initial</span>
                    <Switch
                        onChange={setUseInvestedAmount}
                        checked={useInvestedAmount}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#FFA500"
                        offColor="#444"
                        handleDiameter={20}
                        height={20}
                        width={48}
                    />
                    <span className="text-white ml-3">Montant investi</span>
                </div>
            </div>

            <div className="mb-4 flex flex-col sm:flex-row justify-between gap-4">
                {useInvestedAmount ? (
                    <div className="flex-1">
                        <label className="block text-yellow-400 mb-2" htmlFor="investedAmount">
                            <FontAwesomeIcon icon={faPiggyBank} className="mr-2"/>
                            Montant investi
                        </label>
                        <input
                            type="text"
                            id="investedAmount"
                            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
                            value={investedAmount}
                            onChange={(e) => handleInvestedAmountChange(e.target.value)}
                            required
                        />
                    </div>
                ) : (
                    <div className="flex-1">
                        <label className="block text-yellow-400 mb-2" htmlFor="initialBenefitsPercentage">
                            <FontAwesomeIcon icon={faPercentage} className="mr-2"/>
                            Pourcentage de bénéfices initial
                        </label>
                        <input
                            type="text"
                            id="initialBenefitsPercentage"
                            className="w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            value={initialBenefitsPercentage}
                            onChange={(e) => handleBenefitsPercentageChange(e.target.value)}
                            required
                        />
                    </div>
                )}
            </div>

            {/* DatePicker personnalisé */}
            <div className="mb-4 w-full">
                <label className="block text-yellow-400 mb-2" htmlFor="datePicker">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>
                    Date de la transaction
                </label>
                <DatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                    dateFormat="dd/MM/yyyy HH:mm"    // Format date + heure
                    showTimeSelect                   // Activer la sélection de l'heure
                    timeFormat="HH:mm"               // Format de l'heure
                    timeIntervals={15}               // Intervalles de 15 minutes pour le temps
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    id="datePicker"
                />
            </div>

            <div className="flex justify-end">
                <button
                    type="submit"
                    className="flex items-center bg-green-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-green-700 transition duration-300 ease-in-out"
                    disabled={isLoading}
                >
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                    {isLoading ? 'En cours...' : 'Ajouter'}
                </button>
            </div>
        </form>
    );
};

export default CreatePositionForm;
