import useWebSocket from 'react-use-websocket';

const websocketUrl = process.env.REACT_APP_ENVIRONMENT === 'development'
    ? process.env.REACT_APP_WEBSOCKET_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_WEBSOCKET_BASE_URL;

export const useMyWebSocket = () => {
    const { sendMessage, lastMessage, readyState } = useWebSocket(websocketUrl);

    const isConnected = readyState === WebSocket.OPEN;

    return {
        sendMessage,
        lastMessage,
        isConnected,
    };
};
