import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';

const AssetCategoriesList = ({ assetCategories, onSelectCategory }) => {
    const { user, assetCategoriesSummary } = useContext(UserContext);
    const [hoveredCategory, setHoveredCategory] = useState(null); // État pour suivre la catégorie survolée

    return (
        <div className="flex flex-col">
            <div className="text-2xl font-bold text-gray-200 mb-4">Choisissez une catégorie d'actifs</div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {assetCategories.map((category) => {
                    return (
                        <div
                            key={category.value}
                            onClick={() => onSelectCategory(category)}
                            onMouseEnter={() => setHoveredCategory(category.value)}
                            onMouseLeave={() => setHoveredCategory(null)}
                            className={`relative cursor-pointer p-6 rounded-lg shadow-lg flex items-center justify-between transform transition-all duration-500 ease-in-out ${
                                hoveredCategory === category.value
                                    ? 'bg-yellow-500 scale-105 shadow-2xl saturate-150'
                                    : 'bg-gray-800 scale-100 shadow-lg saturate-100'
                            } hover:shadow-xl`}
                        >
                            <div className="text-yellow-500 text-3xl mr-4">
                                {category.icon}
                            </div>
                            <div className="flex-grow">
                                <h3 className="text-xl font-bold text-white">{category.label}</h3>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AssetCategoriesList;
