import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../../api';
import PortfolioTable from './PortfolioTable';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useWebSocket from 'react-use-websocket';
import {useMyWebSocket} from "../../websocket";  // Import du hook WebSocket

const PortfolioList = ({ assetCategories }) => {
    const { category } = useParams();  // Récupère le type de portefeuille via l'URL paramétrée
    const [portfolios, setPortfolios] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [liveValue, setLiveValue] = useState(null);  // Stocke la valeur reçue via WebSocket

    // URL du WebSocket
    const { sendMessage, lastMessage, isConnected } = useMyWebSocket();  // Utilisation du hook WebSocket

    // Charger les données du portefeuille depuis l'API
    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const response = await api.get('/portfolios/by-type/', {
                    params: { type: category }
                });
                setPortfolios(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des portefeuilles', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPortfolios();
    }, [category]);

    useEffect(() => {
        if (isConnected) {
            sendMessage(JSON.stringify({ portfolio_type: category }));
        }
    }, [isConnected, sendMessage]);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            setLiveValue(data);
            setIsLoading(false);
        }
    }, [lastMessage]);

    // Afficher un loader pendant le chargement des données API ou WebSocket
    if (isLoading || liveValue === null) {
        return <Loader />;
    }

    const categoryLabel = assetCategories.find(c => c.value === category)?.label || category;

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between items-center mb-4">
                    <Link to={`/`} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </Link>
                    <h2 className="text-3xl font-bold text-yellow-500 text-center">
                        Liste des portefeuilles - {categoryLabel}
                    </h2>
                    <div className="w-16"></div>
                </div>
                <PortfolioTable portfolios={portfolios} liveValue={liveValue} />
            </div>
        </div>
    );
};

export default PortfolioList;
