import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import {formatAmount} from "../../utils";

const CreateTransaction = ({ position, onClose, onCreate, transactionType }) => {
    const [transactionAmount, setTransactionAmount] = useState('');
    const [date, setDate] = useState(new Date());
    const [quantity, setQuantity] = useState(0);

    const handleSubmit = () => {
        onCreate(position.asset.id, 0, date, quantity, transactionType, transactionAmount);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50"></div>
            <div className="bg-gray-900 rounded-lg shadow-lg p-6 z-10 w-full max-w-md mx-auto">
                <h2 className="text-2xl text-white mb-4">Créer une transaction</h2>

                <div className="bg-gray-800 p-4 rounded-lg mb-4 flex items-center">
                    {position.asset.image_url && (
                        <img
                            src={position.asset.image_url}
                            alt={position.asset.name}
                            className="w-12 h-12 object-cover rounded-full mr-4"
                        />
                    )}
                    <div>
                        <h3 className="text-lg text-white font-bold">Actif: {position.asset.name}</h3>
                        <p className="text-gray-400">Quantité disponible: {parseFloat(position.quantity).toFixed(2)}</p>
                        <p className="text-gray-400">Valeur actuelle: {formatAmount(position.current_amount)}</p>
                    </div>
                </div>


                <div className="mb-4">
                    <label className="block text-white mb-2">Quantité</label>
                    <div className="flex">
                        <input
                            type="number"
                            className="w-full p-2 rounded bg-gray-800 text-white"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        {transactionType === 'sell' && (
                            <button
                                onClick={() => setQuantity(position.quantity)}
                                className="ml-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-200"
                            >
                                Tout
                            </button>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Montant de la transaction</label>
                    <input
                        type="number"
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        onChange={(e) => setTransactionAmount(e.target.value)}
                        placeholder="Saisissez le montant"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-white mb-2">Type de transaction</label>
                    <input
                        value={transactionType == 'buy' ? 'Achat' : 'Vente'}
                        className="w-full p-2 rounded bg-gray-800 text-white"
                        disabled
                    >
                    </input>
                </div>

                <div className="mb-4 w-full">
                    <label className="block text-yellow-400 mb-2" htmlFor="datePicker">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>
                        Date de la transaction
                    </label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        id="datePicker"
                    />
                </div>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-400 transition duration-200"
                    >
                        Créer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateTransaction;
