import React, { useState, useEffect, useContext } from 'react';
import api from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Unauthorized from "../Unauthorized";
import Loader from "../Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { UserContext } from "../../context/UserContext";
import PositionList from '../positions/PositionList';
import DeletePortfolioModal from './DeletePortfolioModal';
import PortfolioHeader from './PortfolioHeader';
import useWebSocket from 'react-use-websocket';
import {useMyWebSocket} from "../../websocket";  // Import du hook WebSocket

Modal.setAppElement('#root');

const PortfolioHistory = ({ assetCategories }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [unauthorized, setUnauthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [portfolio, setPortfolio] = useState({});
    const [history, setHistory] = useState([{}]);
    const [refreshView, setRefreshView] = useState(false);
    const [positions, setPositions] = useState([]);
    const [timeRange, setTimeRange] = useState('1d');
    const [image, setImage] = useState('');
    const { preferences } = useContext(UserContext);
    const { hideAmounts } = preferences;

    const { sendMessage, lastMessage, isConnected } = useMyWebSocket();  // Hook pour WebSocket

    const [liveValue, setLiveValue] = useState(null);

    useEffect(() => {
        fetchData();
        fetchPositions();
        fetchHistory();
        setRefreshView(false);
    }, [id, refreshView]);

    useEffect(() => {
        fetchHistory(timeRange);
    }, [timeRange]);

    const fetchHistory = async (timeRange) => {
        try {
            const response = await api.get(`/portfolios/${id}/history/`, {
                params: { time_range: timeRange },
            });
            setHistory(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'historique', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`/portfolios/${id}/`);
            const portfolio = response.data;
            setPortfolio(portfolio);
            setImage(portfolio.image_url);
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
            if (error.response && error.response.status === 403) {
                setUnauthorized(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPositions = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`/portfolios/${id}/positions/`);
            setPositions(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des positions', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            sendMessage(JSON.stringify({ portfolio_id: portfolio.id }));
        }
    }, [isConnected, sendMessage]);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            setLiveValue(data);
        }
    }, [lastMessage]);

    const openDeleteModal = () => {
        setDeleteModalIsOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalIsOpen(false);
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/portfolios/${id}/`);
            navigate('/');
        } catch (error) {
            console.error('Erreur lors de la suppression du portefeuille', error);
        }
    };

    const handleAddPosition = () => {
        const category = portfolio.type;
        navigate(`/create-position/${id}`, { state: { category, positions } });
    };

    if (isLoading || !liveValue) {
        return <Loader />;
    }

    if (unauthorized) {
        return <Unauthorized />;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between mb-4">
                    <Link to={`/portfolio-list/${portfolio.type}`} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </Link>
                    <button
                        onClick={openDeleteModal}
                        className="flex items-center bg-red-600 text-white text-lg py-2 px-4 rounded shadow hover:bg-red-700 transition duration-300 ease-in-out">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    </button>
                </div>
                <PortfolioHeader
                    portfolio={portfolio}
                    hideAmounts={hideAmounts}
                    image={image}
                    setTimeRange={setTimeRange}
                    fetchData={fetchData}
                    timeRange={timeRange}
                    categoryLabel={assetCategories.find((c) => c.value === portfolio.type)?.label}
                    history={history}
                    liveValue={liveValue}
                />
                <PositionList
                    positions={positions}
                    handleAddPosition={handleAddPosition}
                    setRefreshView={setRefreshView}
                    refreshView={refreshView}
                    portfolioId={id}
                    hideAmounts={hideAmounts}
                    liveValue={liveValue}
                />
                <DeletePortfolioModal
                    isOpen={deleteModalIsOpen}
                    onRequestClose={closeDeleteModal}
                    onDelete={handleDelete}
                />
            </div>
        </div>
    );
};

export default PortfolioHistory;
