import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import TotalValueOverTimeChart from './charts/TotalValueOverTime';
import { printAmountWithBenefits } from "../utils";
import Loader from "./Loader";
import { UserContext } from "../context/UserContext";
import AssetCategoriesTable from "./assets/AssetCategoriesTable";
import AssetCategoriesRepartition from './charts/AssetCategoriesRepartition';
import { useMyWebSocket } from '../websocket'; // Utilisation de react-use-websocket

function Dashboard({ assetCategories }) {
    const [isLoading, setIsLoading] = useState(true);
    const [timeRange, setTimeRange] = useState('1d');
    const [portfoliosHistory, setPortfoliosHistory] = useState([]);
    const [liveGlobalValue, setLiveGlobalValue] = useState(0);
    const { preferences, portfoliosOverview } = useContext(UserContext);
    const { hideAmounts } = preferences;

    // Utiliser le hook WebSocket
    const { sendMessage, lastMessage, isConnected } = useMyWebSocket();

    // Effet pour envoyer un message WebSocket lorsque la connexion est établie
    useEffect(() => {
        if (isConnected) {
            sendMessage(JSON.stringify({ all_portfolios: true }));
        }
    }, [isConnected, sendMessage]);

    // Effet pour traiter les messages reçus via WebSocket
    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            console.log(data)
            setLiveGlobalValue(data);
            setIsLoading(false);
        }
    }, [lastMessage]);

    // Charger l'historique des portefeuilles lors du changement de plage temporelle
    useEffect(() => {
        fetchHistory(timeRange);
    }, [timeRange]);

    const fetchHistory = async (timeRange) => {
        try {
            const response = await api.get(`/portfolios/history/?time_range=${timeRange}`);
            setPortfoliosHistory(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'historique', error);
        }
    };

    // Si les données sont encore en cours de chargement
    if (isLoading) {
        return <Loader />;
    }

    // Si aucun portefeuille n'est trouvé ou si portfoliosOverview.categories est undefined
    if (Object.keys(liveGlobalValue?.portfolios_by_type || {}).length === 0) {
        return (
            <div className="flex items-center justify-center h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
                <Link to="/create-portfolio/all" className="bg-yellow-500 text-black text-lg py-2 px-4 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                    Créer mon premier portefeuille
                </Link>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl sm:text-4xl font-bold text-yellow-500 mb-6">
                    Tableau de Bord - {printAmountWithBenefits(liveGlobalValue?.total_value ?? portfoliosOverview.amount, portfoliosOverview.invested, hideAmounts)}
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
                    <div className="w-full lg:col-span-2 bg-gray-800 p-4 rounded-lg shadow-lg">
                        <TotalValueOverTimeChart data={portfoliosHistory} hideAmounts={hideAmounts} timeRange={timeRange} setTimeRange={setTimeRange} />
                    </div>
                    <div className="w-full lg:col-span-1 bg-gray-800 p-4 rounded-lg shadow-lg">
                        <AssetCategoriesRepartition assetCategoriesSummary={liveGlobalValue.portfolios_by_type} assetCategories={assetCategories} hideAmounts={hideAmounts} />
                    </div>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
                    <AssetCategoriesTable assetCategoriesSummary={portfoliosOverview.categories} assetCategories={assetCategories} hideAmounts={hideAmounts} liveGlobalValue={liveGlobalValue} />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
