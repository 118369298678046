import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Turnstile } from '@marsidev/react-turnstile';
import api from '../../../api';

const RegisterStep2 = ({ prevStep, personalInfo, setAccountInfo }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};

        if (!username) validationErrors.username = 'Nom d\'utilisateur requis';
        if (!email) validationErrors.email = 'Email requis';
        if (!password) validationErrors.password = 'Mot de passe requis';
        if (password !== password2) {
            validationErrors.password2 = 'Les mots de passe ne correspondent pas';
        }
        if (!captchaToken) validationErrors.captcha = 'Validation CAPTCHA requise';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            NotificationManager.error('Veuillez corriger les erreurs avant de continuer.');
            return;
        }

        setIsLoading(true); // Définir l'état de chargement à vrai
        try {
            const accountInfo = { username, email, password };
            const response = await api.post('/register/step2/', {
                ...personalInfo,
                ...accountInfo,
                captcha_token: captchaToken
            });

            NotificationManager.success('Compte créé avec succès. Vérifiez votre email pour le code de confirmation.');
            localStorage.setItem('user_id', response.data.user_id); // Stocker l'ID de l'utilisateur dans localStorage
            setAccountInfo(accountInfo); // Enregistrer les informations du compte dans l'état global
            navigate('/email-confirmation'); // Rediriger vers la page de confirmation d'email
        } catch (error) {
            NotificationManager.error('Erreur lors de la création du compte');
            console.error('Erreur lors de la création du compte', error);

            if (error.response && error.response.data.error === 'Captcha validation failed') {
                NotificationManager.error('Captcha invalide');
                setReloadCaptcha(true); // Forcer le rechargement du CAPTCHA
            }
        } finally {
            setIsLoading(false); // Réinitialiser l'état de chargement
        }
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token); // Récupérer le token du CAPTCHA
        setReloadCaptcha(false); // Réinitialiser l'état de rechargement du CAPTCHA
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-center text-yellow-500 mb-4">Informations de connexion</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="username">
                        Nom d'utilisateur
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="password">
                        Mot de passe
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                </div>
                <div className="relative">
                    <label className="block text-yellow-500 text-lg font-bold mb-2" htmlFor="password2">
                        Confirmer le mot de passe
                    </label>
                    <input
                        type="password"
                        id="password2"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-300 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                        required
                    />
                    {errors.password2 && <p className="text-red-500 text-sm mt-1">{errors.password2}</p>}
                </div>

                {/* CAPTCHA */}
                <div className="relative">
                    {!reloadCaptcha && (
                        <Turnstile
                            className="mx-auto"
                            siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            onSuccess={handleCaptchaChange}
                        />
                    )}
                    {errors.captcha && <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>}
                </div>

                <button
                    type="submit"
                    className="w-full py-3 bg-yellow-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    disabled={isLoading}
                >
                    {isLoading ? 'Création du compte...' : 'Créer un compte'}
                </button>
                <button
                    type="button"
                    onClick={prevStep}
                    className="w-full py-3 bg-gray-500 text-black text-lg font-semibold rounded-lg shadow-md hover:bg-gray-400 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 mt-2"
                >
                    Précédent
                </button>
            </form>
        </div>
    );
};

export default RegisterStep2;
