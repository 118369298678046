import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import {FiShoppingCart, FiDollarSign, FiEdit} from 'react-icons/fi';
import { NotificationManager } from 'react-notifications';
import { Circles } from 'react-loader-spinner';
import { UserContext } from "../../context/UserContext";
import api from '../../api';
import TotalValueOverTime from '../charts/TotalValueOverTime';
import { formatAmount } from '../../utils';
import Loader from "../Loader";
import {useMyWebSocket} from "../../websocket";

const TransactionBanner = ({ transaction, hideAmounts }) => {
    // Définition de la couleur du texte en fonction du type de transaction
    const textColor = transaction.transaction_type === 'buy' ? 'text-green-500' : (transaction.transaction_type === 'sell') ? 'text-red-500' : 'text-yellow-500';
    // Définition de l'icône en fonction du type de transaction avec un picto pour chaque type
    const icon = transaction.transaction_type === 'buy' ? <FiShoppingCart /> : (transaction.transaction_type === 'sell') ? <FiDollarSign /> : <FiEdit />;


    return (
        <div className="flex items-center p-4 border-b border-gray-700 mb-4">
            <div className={`text-2xl mr-4 ${textColor}`}>
                {icon}
            </div>
            <div className="flex-grow">
                <p className="font-semibold text-lg">
                    {transaction.transaction_type === 'buy' ? 'Achat' : (transaction.transaction_type === 'sell') ? 'Vente' : 'Edition'}
                </p>
                <p className="text-sm text-gray-400">
                    {transaction.transaction_type == 'buy' || transaction.transaction_type == 'sell' ? (
                        <>
                            {new Date(transaction.date).toLocaleString()} - Quantité: {(transaction.quantity < 1 ? parseFloat(transaction.quantity).toPrecision(8).replace(/\.?0+$/, '') : parseFloat(transaction.quantity).toFixed(2).replace(/\.?0+$/, ''))}, Prix: {formatAmount(parseFloat(transaction.amount_paid).toFixed(2).replace(/\.?0+$/, ''), hideAmounts)}

                        </>
                    ) : (
                        <>
                            {new Date(transaction.date).toLocaleString()}, Montant investi : {formatAmount(transaction.amount_paid, hideAmounts)}, Montant actuel : {formatAmount(transaction.actual_price, hideAmounts)}
                        </>

                    )}
                </p>
            </div>
        </div>
    );

};

const PositionBanner = ({ asset, hideAmounts, position, liveAmount }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previewPic, setPreviewPic] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewPic(URL.createObjectURL(file));
            setSelectedFile(file);
            updateAssetImage(file, asset);
        }
    };

    const updateAssetImage = async (file, asset) => {
        if (!file) {
            NotificationManager.error('Veuillez sélectionner une image.', 'Erreur');
            return;
        }

        setIsSubmitting(true);
        try {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('asset_id', asset.id);

            await api.put(`/assets/${asset.id}/update-image/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            NotificationManager.success('Image de l\'actif mise à jour avec succès !', 'Succès');
        } catch (error) {
            NotificationManager.error('Erreur lors de la mise à jour de l\'image de l\'actif.', 'Erreur');
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleImageClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <div className="flex items-center p-4 border-b border-gray-700 mb-4">
            <div className="relative group w-16 h-16 mr-4">
                {isSubmitting ? (
                    <Circles
                        height="64" // Taille du loader modifiée pour être égale à celle de l'image (16px)
                        width="64"
                        color="#facc15"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                ) : (
                    <>
                        <img
                            src={previewPic || asset.image_url || '/default.jpg'}
                            alt={asset.asset_name}
                            className="w-16 h-16 object-cover rounded-full mr-4 cursor-pointer border-4 border-gray-700 transition-all duration-300 ease-in-out"
                            onClick={handleImageClick}
                        />
                        <div
                            className="absolute inset-0 bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300 cursor-pointer w-16 h-16"
                            onClick={handleImageClick}
                        >
                            <FontAwesomeIcon icon={faPen} className="text-white text-xl" />
                        </div>
                    </>
                )}
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                />
            </div>
            <div className="flex-grow">
                <p className="font-semibold text-lg">{asset.short_name} - {asset.name}</p>
                <p className="text-sm text-gray-400">
                    Quantité: {position.quantity > 0 ? parseFloat(position.quantity) : 0},
                    Valeur actuelle : {formatAmount(liveAmount.current_value || position.current_amount, hideAmounts)}
                </p>
            </div>
        </div>
    );
};

const PositionDetails = () => {
    const { positionId } = useParams();
    const [valueHistory, setValueHistory] = useState([]);
    const [profits, setProfits] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [position, setPosition] = useState(null);
    const [asset, setAsset] = useState(null);
    const [liveAmount, setLiveAmount] = useState(null);  // État pour la valeur en temps réel via WebSocket
    const [timeRange, setTimeRange] = useState('1d');
    const navigate = useNavigate();
    const { preferences } = useContext(UserContext);
    const { hideAmounts } = preferences;

    // URL du WebSocket
    const { sendMessage, lastMessage, isConnected } = useMyWebSocket();

    useEffect(() => {
        const fetchPositionDetails = async () => {
            try {
                const response = await api.get(`/positions/${positionId}/`);
                setPosition(response.data || {});
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de l\'actif', error);
            }
        };

        const fetchProfits = async () => {
            try {
                const response = await api.get(`/profits/by-position/`, {
                    params: { position_id: positionId }
                });
                setProfits(response.data || []);
            } catch (error) {
                console.error('Erreur lors de la récupération des profits', error);
            }
        };

        const fetchTransactions = async () => {
            try {
                const response = await api.get(`/transactions/by-position/`, {
                    params: { position_id: positionId }
                });
                setTransactions(response.data.sort((a, b) => new Date(b.date) - new Date(a.date)) || []);
            } catch (error) {
                console.error('Erreur lors de la récupération des transactions', error);
            }
        };

        fetchProfits();
        fetchTransactions();
        fetchPositionDetails();
    }, [positionId]);

    useEffect(() => {
        if (!position) return;

        const fetchAsset = async () => {
            try {
                const response = await api.get(`/assets/${position.asset.id}/`);
                setAsset(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'asset', error);
            }
        };

        fetchAsset();
    }, [position]);

    useEffect(() => {
        if (isConnected) {
            sendMessage(JSON.stringify({ position_id: position.id }));
        }
    }, [isConnected, sendMessage]);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            setLiveAmount(data);
        }
    }, [lastMessage]);

    useEffect(() => {
        const fetchPositionValueHistory = async (positionId, timeRange) => {
            try {
                const response = await api.get('/positions/position-history/', {
                    params: { position_id: positionId, time_range: timeRange }
                });
                setValueHistory(response.data || []);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'historique de valeur de la position', error);
            }
        };

        fetchPositionValueHistory(positionId, timeRange);
    }, [timeRange, positionId]);

    if (!(position && asset && liveAmount)) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="flex justify-between mb-4">
                    <button onClick={() => navigate(-1)} className="flex items-center text-yellow-500 hover:text-yellow-400">
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
                        <span>Retour</span>
                    </button>
                </div>

                <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                    {/* Afficher l'asset avec la valeur mise à jour via WebSocket */}
                    <PositionBanner asset={asset} hideAmounts={hideAmounts} position={position} liveAmount={liveAmount} />

                    <div className="mb-6">
                        <h3 className="text-xl text-white mb-4">Historique des valeurs</h3>
                        {valueHistory.length > 0 ? (
                            <TotalValueOverTime data={valueHistory} hideAmounts={hideAmounts} setTimeRange={setTimeRange} timeRange={timeRange} />
                        ) : (
                            <p className="text-gray-400">Aucun historique de valeur disponible.</p>
                        )}
                    </div>

                    <div className="mb-6">
                        <h3 className="text-xl text-white mb-4">Profits Réalisés</h3>
                        {profits.length > 0 ? (
                            <ul className="space-y-2">
                                {profits.map(profit => (
                                    <li key={profit.id} className="text-gray-300">
                                        {new Date(profit.date).toLocaleString()}: <span className={profit.amount < 0 ? "text-red-500" : "text-green-500"}>{formatAmount(profit.amount, hideAmounts)}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-400">Aucun profit réalisé disponible.</p>
                        )}
                    </div>

                    <div className="mt-6">
                        <h3 className="text-xl text-white mb-4">Transactions</h3>
                        {transactions.length > 0 ? (
                            <div className="space-y-4">
                                {transactions.map(transaction => (
                                    <TransactionBanner key={transaction.id} transaction={transaction} hideAmounts={hideAmounts} />
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-400">Aucune transaction disponible.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PositionDetails;
