import React, { useContext, useState } from 'react';
import api from '../../api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AssetCategoriesList from '../assets/AssetCategoriesList';
import { UserContext } from "../../context/UserContext";

const CreatePortfolio = ({ assetCategories }) => {
    const { category: predefinedCategory } = useParams();
    const [category, setCategory] = useState(predefinedCategory ? assetCategories.find(c => c.value === predefinedCategory) : {});
    const [step, setStep] = useState(predefinedCategory.length > 0 && predefinedCategory !== 'all' ? 2 : 1);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const { user, portfoliosOverview } = useContext(UserContext);
    const navigate = useNavigate();

    const handleCategorySelect = (selectedCategory) => {
        setCategory(selectedCategory);
        setStep(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('type', category.value);

        try {
            const response = await api.post('/portfolios/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            const portfolioId = response.data.id;

            navigate(`/portfolio-history/${portfolioId}`);
        } catch (error) {
            console.error(error);
            alert('Erreur lors de l\'ajout du portefeuille');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-800 to-gray-700 text-white px-4 py-8">
            {step === 1 && (
                <AssetCategoriesList assetCategories={assetCategories} onSelectCategory={handleCategorySelect} />
            )}
            {step === 2 && category && (
                <div className="max-w-xl w-full bg-gray-900 p-8 rounded-lg shadow-xl border border-gray-700">
                    <div className="flex items-center mb-8">
                        <div className="text-yellow-500 text-4xl mr-4">
                            {category.icon}
                        </div>
                        <h2 className="text-3xl font-bold text-white">Créer un portefeuille - {category.label}</h2>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-8">
                        <div className="relative">
                            <label className="block text-lg font-medium text-gray-200 mb-2" htmlFor="name">
                                Nom du portefeuille
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full px-5 py-3 bg-gray-800 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                placeholder="Ex: Mon Portefeuille"
                                required
                            />
                        </div>
                        <div className="relative">
                            <label className="block text-lg font-medium text-gray-200 mb-2" htmlFor="description">
                                Description
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="w-full px-5 py-3 bg-gray-800 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
                                placeholder="Ex: Ce portefeuille est destiné à ..."
                                rows={4}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="px-6 py-3 bg-yellow-500 text-black text-lg font-semibold rounded-md shadow-lg hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                            >
                                Ajouter
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default CreatePortfolio;
