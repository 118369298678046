import React, { useState } from 'react';
import RegisterStep1 from './steps/RegisterStep1';
import RegisterStep2 from './steps/RegisterStep2';

const Register = () => {
    const [step, setStep] = useState(1);
    const [personalInfo, setPersonalInfo] = useState({});
    const [accountInfo, setAccountInfo] = useState({});

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const getStepComponent = () => {
        switch (step) {
            case 1:
                return <RegisterStep1 nextStep={nextStep} setPersonalInfo={setPersonalInfo} />;
            case 2:
                return <RegisterStep2 prevStep={prevStep} nextStep={nextStep} setAccountInfo={setAccountInfo} />;
            default:
                return <RegisterStep1 nextStep={nextStep} setPersonalInfo={setPersonalInfo} />;
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white">
            <div className="w-full max-w-4xl px-8 py-4 bg-gray-900 rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-8 relative">
                    <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 flex items-center w-full">
                        <div className={`flex-1 h-1 ${step > 1 ? 'bg-yellow-500' : 'bg-gray-500'}`} />
                        <div className={`flex-1 h-1 ${step > 2 ? 'bg-yellow-500' : 'bg-gray-500'}`} />
                    </div>
                    <div className="relative z-20 bg-gray-900 p-2 rounded-full">
                        <div className={`w-8 h-8 flex items-center justify-center rounded-full ${step >= 1 ? 'bg-yellow-500' : 'bg-gray-500'}`}>
                            1
                        </div>
                    </div>
                    <div className="relative z-20 bg-gray-900 p-2 rounded-full">
                        <div className={`w-8 h-8 flex items-center justify-center rounded-full ${step >= 2 ? 'bg-yellow-500' : 'bg-gray-500'}`}>
                            2
                        </div>
                    </div>
                </div>
                {getStepComponent()}
            </div>
        </div>
    );
};

export default Register;
