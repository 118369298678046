import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Oval } from 'react-loader-spinner';  // Import du loader Oval depuis react-loader-spinner

// Fonction pour ajouter des séparateurs de milliers
const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const MegaStyledHighcharts = ({ data, hideAmounts, setTimeRange, timeRange }) => {
    const [loading, setLoading] = useState(true);  // Ajout d'un état de chargement

    // Simulation du temps de chargement (vous pouvez remplacer ça avec une vraie requête API)
    useEffect(() => {
        setLoading(true);
        if (data.length > 0) {
            setLoading(false);
        }
    }, [data]);

    // Transformation des données pour Highcharts
    const transformedData = data.map((item) => ({
        date: new Date(item.date).toLocaleDateString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit'
        }),
        value: item.value,
    }));

    const options = {
        chart: {
            type: 'spline',  // Utilisation de spline pour lisser les courbes
            height: 400,
            backgroundColor: 'transparent',  // Suppression du fond
        },
        title: {
            text: null,  // Suppression du titre
        },
        xAxis: {
            categories: transformedData.map(item => item.date),
            lineColor: '#ccc',  // Ligne X discrète
            labels: {
                enabled: false,  // Désactivation des labels sur l'axe X
            },
            min: 0,  // Fixer le premier point à gauche
            max: transformedData.length - 1,  // Fixer le dernier point à droite
        },
        yAxis: {
            title: {
                text: null,  // Suppression du titre de l'axe Y
            },
            labels: {
                formatter: function () {
                    return hideAmounts ? '' : formatNumber(this.value);
                },
                style: {
                    color: '#FFFFFF',  // Éclaircir la couleur des labels
                }
            },
            gridLineColor: 'rgba(200, 200, 200, 0.2)',  // Grille discrète
        },
        series: [
            {
                name: 'Montant actuel',
                data: transformedData.map(item => item.value),
                color: '#1E90FF',
                lineWidth: 3,
                marker: {
                    enabled: false  // Désactivation des points sur le graphe
                },
            },
        ],
        tooltip: {
            shared: true,  // Afficher les deux séries dans la tooltip
            backgroundColor: 'rgba(0, 0, 0, 0.9)',  // Fond foncé pour la tooltip
            borderColor: '#333',
            borderRadius: 5,
            style: {
                color: '#FFFFFF',  // Texte blanc dans la tooltip
                fontSize: '12px',
            },
            formatter: function () {
                if (this.points && this.points.length > 0) {
                    const currentValue = this.points[0] ? this.points[0].y.toFixed(2) : 'N/A';

                    // Gestion de l'affichage du montant en fonction de hideAmounts
                    return hideAmounts
                        ? `<b>${this.x}</b><br/><span style="color:#1E90FF">●</span> Montant actuel : Caché`
                        : `<b>${this.x}</b><br/><span style="color:#1E90FF">●</span> Montant actuel : ${formatNumber(currentValue)} €<br/>`;
                }
                return `<b>${this.x}</b><br/>Pas de données disponibles.`;
            }
        },
        legend: {
            enabled: false,  // Suppression de la légende
        },
        credits: {
            enabled: false  // Suppression de la mention "highcharts.com"
        },
    };

    return (
        <div className="w-full">
            {/* Choix de la période */}
            <div className="flex justify-end space-x-2 mb-4">
                <button
                    onClick={() => setTimeRange('1d')}
                    className={`px-3 py-1 rounded ${timeRange === '1d' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1J
                </button>
                <button
                    onClick={() => setTimeRange('1w')}
                    className={`px-3 py-1 rounded ${timeRange === '1w' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1S
                </button>
                <button
                    onClick={() => setTimeRange('1m')}
                    className={`px-3 py-1 rounded ${timeRange === '1m' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1M
                </button>
                <button
                    onClick={() => setTimeRange('1y')}
                    className={`px-3 py-1 rounded ${timeRange === '1y' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    1A
                </button>
                <button
                    onClick={() => setTimeRange('all')}
                    className={`px-3 py-1 rounded ${timeRange === 'all' ? 'bg-yellow-500 text-black' : 'bg-gray-700 text-white'}`}
                >
                    Tout
                </button>
            </div>

            {/* Affichage du loader si les données sont en cours de chargement */}
            {loading ? (
                <div className="flex justify-center items-center h-96">
                    <Oval
                        height={60}
                        width={60}
                        color="#1E90FF"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />  {/* Loader Oval animé */}
                </div>
            ) : (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}
        </div>
    );
};

export default MegaStyledHighcharts;
