import React from 'react';
import AssetCategoryCard from './AssetCategoryCard';
import { Link } from 'react-router-dom';

const AssetCategoriesTable = ({ assetCategoriesSummary, assetCategories, hideAmounts, liveGlobalValue }) => {
    // Trier les catégories par la valeur totale de manière décroissante
    assetCategoriesSummary = Object.fromEntries(Object.entries(assetCategoriesSummary).sort((a, b) => b[1].total_value - a[1].total_value));

    return (
        <div className="w-full">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-yellow-500">Catégories d'Actifs</h3>
                <Link to="/create-portfolio/all"
                      className="bg-yellow-500 text-black px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300 ease-in-out">
                    Créer un Portefeuille
                </Link>
            </div>

            {/* Utilisation d'un grid flexible et responsive */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.keys(assetCategoriesSummary).map((category) => (
                    <div className="h-full" key={category}>
                        <Link
                            to={`/portfolio-list/${category}`}
                            className="no-underline text-white h-full"
                        >
                            <AssetCategoryCard
                                label={assetCategories.find((c) => c.value === category)?.label}
                                icon={assetCategories.find((c) => c.value === category)?.icon}
                                amount={assetCategoriesSummary[category]?.totalAmount}
                                invested={assetCategoriesSummary[category]?.totalInvested}
                                totalPortfolios={assetCategoriesSummary[category]?.portfoliosCount}
                                hideAmounts={hideAmounts}
                                liveGlobalValue={liveGlobalValue.portfolios_by_type[category]}
                                // Ajouter la classe responsive ici
                                className="text-base md:text-lg lg:text-xl whitespace-nowrap overflow-hidden text-ellipsis"
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssetCategoriesTable;
